<template>
    <div>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="SQLs Integração">
                <SqlsErp />
            </CTab>
        </CTabs>
    </div>
</template>

<script>
  import SqlsErp from "./sqls/SQLsErpIntegracao";

  export default {
    name: "Logs",
    components: {
      SqlsErp
    },
  }
</script>