<template>
  <modal :open="true" name="simulate-query" height="auto" width="1024" :lazy="true" :scrollable="true" :clickToClose="false">
    <CloseModal :close="closeModal" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div v-if="isFetching" class="">
          <LoadingMessage message="Carregando, aguarde" is-active align-center />
        </div>
        <div v-else-if="errorMessage" class="alert alert-danger">
          <pre>{{ errorMessage }}</pre>
        </div>
        <table-paginated
            v-else
            :itens="result.itens"
            :columns="columns"
            :paginator="result.paginator"
            :onPageChange="executeQuery"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" v-on:click="executeQuery">
        Reexecutar
      </button>
      <button type="button" class="btn btn-secondary" v-on:click="closeModal">
        Fechar
      </button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../components/CloseModal";
import TablePaginated from '../../../components/crud/TablePaginated';
import {post} from "@/helpers/apiRequest";
import map from 'lodash/map';
import isNull from 'lodash/isNull';

export default {
  name: "ModalSimulateQuery",
  components: {
    CloseModal,
    TablePaginated,
  },
  props: [
    'sql'
  ],
  data () {
    return {
      errorMessage: null,
      isFetching: false,
      result: {
        itens: [],
        paginator: {},
      },
      columns: [],
    }
  },
  watch: {
    "sql": function() {
      if (!!this.sql) {
        this.executeQuery(1);
      }
    },
  },
  methods: {
    executeQuery(page = 1) {
      this.isFetching = true;
      post(`/admin/sqls-erp/simulate?page=${page}`, { sql: this.sql })
          .then(json => {
            this.isFetching = false;
            this.errorMessage = null;
            this.result = json;

            if (json.itens.length > 0) {
              let newColumns = [];
              map(json.itens[0], (value, keyName) => {
                newColumns.push({
                  label: keyName,
                  field: keyName,
                  sortable: false,
                  representedAs: (row) => isNull(row[keyName]) ? 'NULL' : row[keyName]
                });
              });
              this.columns = newColumns;
            }
          })
          .catch(e => {
            this.isFetching = false;
            this.errorMessage = e.message;
          })
    },
    closeModal() {
      this.$modal.hide('simulate-query');
    }
  }
}
</script>